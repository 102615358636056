import { ReactNode } from 'react';
import { cn } from '~/lib/utils';

interface FooterProps {
  sticky?: boolean;
  children: ReactNode;
}

export default function Footer({ sticky = true, children }: FooterProps) {
  return (
    <div
      className={cn(`flex flex-col w-full bg-rf-gray-f9 p-4 space-y-2
	  sm:max-w-lg xl:max-w-xl mx-auto z-30
      ${sticky ? 'sticky bottom-0' : ''} flex justify-center`)}
    >
      {children}
    </div>
  );
}
